.slick-arrow {
  font-size: 0 !important;
}

.slick-prev {
  left: 93% !important;
}

.tab-pane {
  margin: 30px 0;
}

.my-account-tabs {
  height: 200px;
}

.content-page {
  margin-top: 0 !important;
  padding: 10px !important;
  padding-top: 70px !important;
  height: 100vh !important;
  overflow-y: scroll !important;
}

.dashboard .row {
  margin: 20px 0 !important;
}

.card {
  height: 100% !important;
}

.custom-footer {
  padding: 19px 15px 20px;
  right: 0;
  color: #98a6ad;
  left: 240px;
  background-color: #eeeff3;
}

.action-icon {
  color: #98a6ad !important;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px;
}

@media (max-width: 991.98px) {
  .left-side-menu {
    display: block !important;
    transform: translateX(-100%) !important;
    z-index: 10 !important;
  }

  .left-side-menu.show {
    transform: translateX(0) !important;
    z-index: 10 !important;
  }
}

.modal-dialog {
  max-width: 600px !important;
}

.modal-content {
  border: 3px solid #a158a1 !important;
  border-radius: 10px !important;
}

.modal-content.no-border {
  border: 0px !important;
  border-radius: 0px !important;
}

.modal-btn {
  padding: 8px 30px;
  display: inline-block;
  padding: 12px 40px;
  color: #ffffff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  background: #f53538;
}

.modal-btn.secondary {
  background: black;
}

.modal-btn:hover {
  color: white;
}

.main-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: black;
}

.modal-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 4;
  padding: 30px 10px;
  text-align: center;
}

.modal-inner p b {
  color: #a158a1;
}

.our-profile-area-custom {
  margin-left: 15px;
  position: relative;
}

.email-err {
  color: #f53538 !important;
  min-height: 20px !important;
}

.permission-box {
  font-size: 16px;
}

.permission-box .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.permission-box .head h3 {}

.permission-box .head p {}

.permission-box .head p span {
  font-size: 24px;
}

.permission-box table {
  width: 100%;
  margin: 10px 0;
}

.permission-box table .table-head {
  font-size: 18px;
  width: 100%;
  font-weight: bold;
}

.permission-box table td {
  text-align: center;
  height: 30px !important;
}

.permission-box table td.detailed {
  text-align: center;
  height: 50px !important;
}

.permission-box table td.text-left {
  text-align: left;
}

.permission-box table tr {
  padding: 5px 0;
}

.permission-box button {
  font-size: 16px;
  font-weight: 600;
  outline: none;
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
}

.pricing-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.pricing-card button {
  border: 0px !important;
  outline: none !important;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

.invisible-scroll::-webkit-scrollbar {
  width: 0;
  background: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: none;
  overflow: -moz-scrollbars-none;
}

.invisible-scroll {
  overflow: scroll;

}

body::-webkit-scrollbar {
  width: 0;
  background: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: none;
  overflow: -moz-scrollbars-none;
}

/* width */
.visble-scroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.visble-scroll::-webkit-scrollbar-track {
  background: #272727;
}

/* Handle */
.visble-scroll::-webkit-scrollbar-thumb {
  background: #f53538;
}

/* Handle on hover */
.visble-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55vh;
}

.loader>div {
  width: fit-content;
}

.pro-sidebar {
  width: 100%;
  min-width: 100%;
  background-color: #000 !important;
}

.pro-sidebar>.pro-sidebar-inner {
  background: #000 !important;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  background: rgb(12, 12, 12) !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
  color: #fff !important;
  font-size: 18px !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item svg {
  width: 20px !important;
  height: 20px !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover {
  color: red !important;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  background-color: transparent;
}

$sidebar-width: 240px !default;

.pro-sidebar .title .pro-item-content {
  font-size: 12px !important;
  color: #f53538 !important;
}

.left-side-menu>div.admin {
  overflow-y: hidden !important;
}

.left-side-menu>div {
  overflow-y: scroll !important;
}

.dropdown-container {
  display: none;
  height: 0px;
  background-color: #0c0c0c;
  padding-left: 8px;
  flex-direction: column !important;
  transition: width 2s, height 4s;
}

.dropdown-container li a {
  color: white !important;
}

.dropdown-container.show {
  display: flex;
  height: fit-content;
}

.drop-icon>svg {
  transition: 500ms;
}

.drop-icon.rotate-90>svg {
  transform: rotate(90deg) !important;
}

.ql-editor {
  min-height: 250px !important;
}

.table-responsive .movie-thumbnail {
  width: 50px !important;
  height: 50px !important;
  object-fit: cover;
}

.table-nowrap th,
.table-nowrap td {
  max-width: 100px !important;
  overflow: hidden;
}

.table-nowrap th.name,
.table-nowrap td.name {
  max-width: fit-content !important;
  min-width: fit-content !important;
  margin-right: 10px;
}

.movie-wrap-plr {
  height: 100%;
}

.movie-wrap-plr>div {
  height: 100%;
}

.movie-wrap-plr .view-div {
  height: 100%;
}

.movie-wrap-plr .view-all {
  height: 100%;
}

.movie-wrap-plr .view-all img {
  height: 100%;
}

.genre-wrap {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  position: relative;
  background-color: rgb(27, 27, 27);
}

.genre-wrap:hover {
  background-color: transparent;
}

.genre-wrap>img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: none;
  border-radius: 10px;
}

.genre-wrap:hover>img {
  display: block;
}

.genre-wrap:hover img {
  border: 2px solid #fff !important;
  opacity: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.genre-wrap img {
  opacity: 70%;
}

.genre-wrap .title,
.genre-wrap .title a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff !important;
}

.single-movielist .movielist-img-content .movielist-img {
  flex: 0 0 90px;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 4px;
}

.Watch-list-btn {
  position: relative;
}

.watchlist-info {
  position: absolute;
  width: fit-content;
  left: -20px;
  font-size: 16px;
  white-space: nowrap;
  padding: 5px 10px;
  z-index: 9999;
  background-color: #f53538;
}

.loading-container {}

.loading-container>div {
  width: fit-content !important;
  margin: auto;
}

.stripe-checkout-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 300px;
  text-align: left;
  padding: 20px;
}

.stripe-checkout-form .StripeElement {
  margin: 5px 0;
  border: 2px solid gray;
  padding: 5px 10px;
  border-radius: 4px;
}

.stripe-checkout-form label>div input {
  margin: 5px 0;
  border: 2px solid gray;
  padding: 5px 10px;
  border-radius: 4px;
  width: 100%;
}

.stripe-checkout-form .StripeElement {}

.stripe-checkout-form button {
  margin: 5px 0;
  border: 2px solid gray;
  padding: 5px 10px;
  border-radius: 5px;
}

::-webkit-input-placeholder {
  font-weight: 600 !important;
}

#sidebar-menu>ul>li>a.active span {
  color: #f53538 !important;
}

.payment-method-form {
  width: 100%;
  text-align: left;
}

.video-not-allowed {
  width: 100%;
  height: 400px;
  background-color: #eeeff3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.active {
  color: #f53538 !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.hero-slide .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination>span {
  background: white !important;
}

.profile-slider {}

.edit-profile-img {
  position: relative;
}

.profile-card {
  background-color: none !important;
}

.profile-card.main {
  height: 290px;
}

.profile-card:hover {
  transform: scale(1.2);
}

.profile-card .details {}

.profile-card .details h4 {
  color: #fff !important;
}

.profile-img {
  cursor: pointer;
  position: relative;
  padding: 10px;
  border-radius: 50%;
  background-color: rgb(51, 50, 50);
  transition: 300ms;
  z-index: -90;
  margin: 10px;
  width: 200px !important;
  height: 200px !important;
  overflow: hidden;
}

.profile-img .main-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.img-container .text-image {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  display: none;
  transition: 500ms;
}

.img-container:hover .text-image {
  bottom: 0px;
  display: block;
}

.video-slider-section {
  padding: 20px 0px;
}

.video-slider-section>p {
  padding: 0 40px;
  font-size: 22px;
  font-weight: 600;
  color: white;
}

.video-slider-section>div {
  padding: 0 40px;

}

.video-slider-section>div>button {
  font-size: 16px;
  font-weight: bold;
  color: white;
  background: #f53538;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.video-slider-section>div>button i {
  margin-left: 5px;
}


.video-slider-section>div>p {
  font-size: 22px;
  font-weight: 600;
  color: white;
  margin: 0;
}

.video-card-v2 {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.video-card-v2.top-border * {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.video-card-v2 .progress-container {
  width: 100%;
  position: absolute;
  /* height: 100%; */
  bottom: 3px;
  left: 0;
}

.video-card-v2 .progress-container .progress {
  height: 5px;
}

.video-card-v2 .progress-container * {
  border-radius: 0 !important;
}

.video-card-v2 a {
  border-radius: 10px;
  overflow: hidden;
}

.video-card-v2 img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.01);
}

.video-card-v2 .text-section {
  width: 100%;
  text-align: left;
  position: absolute;
  bottom: 0;
  color: white;
  padding: 10px;
  transition: 500ms;
  transform: translateY(100%);
  overflow: hidden;
}

.video-card-v2 .text-section p {
  font-size: 14px;
}

.video-card-v2 .text-section .title {
  font-size: 18px;
  font-weight: bold;
  transition: 500ms;
}

.video-card-v2 .text-section .title:hover {
  color: #f53538;
}

.video-card-v2:hover img {
  transform: scale(1.1);
}

.video-card-v2:hover .text-section {
  transform: translateY(0);
  box-shadow: -1px -23px 142px 82px rgba(0, 0, 0);
  -webkit-box-shadow: -1px -23px 142px 82px rgba(0, 0, 0);
  -moz-box-shadow: -1px -23px 142px 82px rgba(0, 0, 0);
  background-color: #000000d2;
  box-shadow: -1px -23px 142px 82px rgb(0 0 0);
  -webkit-box-shadow: 0px -47px 126px 102px #000000de;
}

.video-slider {
  padding: 0 40px;
}

.video-slider .swiper-slide {}

.swiper-button-prev.swiper-button-disabled {
  display: none !important;
}

.swiper-button-next.swiper-button-disabled {
  display: flex !important;
}

.swiper-slide {
  height: 100%;
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNzUycHQiIGhlaWdodD0iNzUycHQiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDc1MiA3NTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8cGF0aCBkPSJtMjk5LjEyIDM3NiAxMy4zMi0xMy40NjkgMTEzLjY2LTExMy42NiAyNi43ODUgMjYuOTM0LTEwMC4xOSAxMDAuMTkgMTAwLjE5IDEwMC4xOS0yNi43ODUgMjYuOTM0LTExMy42Ni0xMTMuNjYtMTMuMzItMTMuNDY5eiIvPgo8L3N2Zz4K");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-color: rgba(255, 0, 0, 0.349);
  border-radius: 50%;
  padding: 0px 22px;
}

.swiper-button-prev:hover {
  background-color: rgba(255, 0, 0, 1);
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNzUycHQiIGhlaWdodD0iNzUycHQiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDc1MiA3NTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8cGF0aCBkPSJtNDUyLjg4IDM3Ni0xMy4zMi0xMy40NjktMTEzLjY2LTExMy42Ni0yNi43ODUgMjYuOTM0IDEwMC4xOSAxMDAuMTktMTAwLjE5IDEwMC4xOSAyNi43ODUgMjYuOTM0IDExMy42Ni0xMTMuNjYgMTMuMzItMTMuNDY5eiIvPgo8L3N2Zz4K");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-color: rgba(255, 0, 0, 0.349);
  border-radius: 50%;
  padding: 0px 22px;
}

.swiper-button-next:hover {
  background-color: rgba(255, 0, 0, 1);
}

.swiper-button-next::after {
  display: none;
}

.header-area-v2 {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 99;
  width: 100%;
  height: fit-content;
  background-color: black;
  box-shadow: inset 0 67px 25px -3px rgb(0 0 0 / 51%);
}

.header-area-v2.transparent {
  background: transparent;
}

.header-area-v2 .our-profile-area::before {
  display: none;
}

.header-area-v2 .first-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  box-shadow: inset 0 67px 25px -3px rgb(0 0 0 / 51%);
}

.header-area-v2 .nav-large {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #222222c9;
  border-radius: 40px;
  padding: 0px;
  height: fit-content;
}

.header-area-v2 .nav-large a {
  padding: 10px 15px;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  color: white;
  border-radius: 40px;
  text-transform: uppercase;
}

.header-area-v2 .nav-large a:hover {
  color: #f53538;
}

.header-area-v2 .nav-large a:first-child {
  background-color: #f53538;
}

.header-area-v2 .nav-large a:first-child:hover {
  color: white;
}

.header-area-v2 .nav-large .nav-link.active:hover {
  color: white;
}

.header-area-v2 .nav-small {
  display: none;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  max-width: 100%;
  overflow: scroll;
  margin-top: 20px;
  white-space: nowrap;
}

.header-area-v2 .nav-small a {
  padding: 10px 15px;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  color: white;
  border-radius: 40px;
}

.header-area-v2 .nav-small a:hover {
  color: #f53538;
}

.mobile-menu .mean-bar .mean-nav {
  top: 52% !important;
}

.user-layout {}

.user-layout .hero-slider {
  height: 100vh;
}

.user-layout .hero-slider .single-hero-img-2 {
  height: 100%;
  width: 100%;
  position: relative;
}

.user-layout .hero-slider .single-hero-img-2 .bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: initial;
}

.user-layout .hero-slider .single-hero-img-2 .content {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  text-align: left;
  color: white;
  padding: 10px;
}

.user-layout .hero-slider .single-hero-img-2 .content .text-img {
  width: 300px;
  max-width: 100%;
  margin-right: auto;
  margin-bottom: 10px;
}

.user-layout .hero-slider .single-hero-img-2 .content .video-detail-cont {
  display: flex;
  align-items: center;
  margin-top: 20px;
  white-space: nowrap;
}

.user-layout .hero-slider .single-hero-img-2 .content .video-detail-cont span {
  margin-right: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.user-layout .hero-slider .single-hero-img-2 .content .button-div {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
}

.user-layout .hero-slider .single-hero-img-2 .content>div>div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.user-layout .hero-slider .single-hero-img-2 .content .desc-text {
  width: 70%;
  min-width: 300px;
  margin-bottom: 100px;
}

@media screen and (max-width: 1000px) {
  .header-area-v2 .nav-large {
    display: none;
  }

  .header-area-v2 {
    background: #000 !important;
    border-bottom: 4px solid #f53538;
    position: inherit;
  }

  .header-area-v2.transparent {
    background: black;
  }

  .header-area-v2 .nav-small {
    display: flex;
    background-color: #000 !important;
  }

  .hero-slider {
    height: 380px !important;
  }

  .user-layout .hero-slider .single-hero-img-2 .content {
    bottom: 20px;
  }

  .user-layout .hero-slider .single-hero-img-2 .content .video-detail-cont {
    display: none;
  }

  .user-layout .hero-slider .single-hero-img-2 .content .desc-text {
    display: none;
  }

  .user-layout .hero-slider .single-hero-img-2 .content .text-img {
    width: 180px;
  }

  .profile-page .title.full {
    flex-direction: column !important;
  }

  .profile-card {
    margin-top: 70px;
  }

  .user-layout .hero-slider .single-hero-img-2 .bg {
    object-position: right;
  }
}


@media screen and (max-width: 768px) {
  .hero-slider {
    height: 300px !important;
  }
}

.video-card-v2 .Watch-list-btn {
  position: absolute;
  border: none;
  top: 15px;
  right: 15px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #f53538;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: 500ms;
}

.video-card-v2:hover .Watch-list-btn {
  display: flex;
}

.video-card-v2 .Watch-list-btn i {
  font-weight: 600;
  color: #fff;
}

.profile-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  position: fixed;
}

.profile-bg {
  height: 100vh;
  width: 100vw;
}

.profile-page .title {
  color: #eeeff3;
  font-weight: 600;
  position: absolute;
  top: 70px;
  left: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
  font-size: 20px !important;
}

.profile-page .title.full {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 90vw;
}

.profile-page .title a {
  color: #ff0000;
}

.profile-page .title h3 {
  color: #eeeff3;
  font-size: 28px;
  margin-left: 10px;
}

.edit-profile-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}

.profile-bg {
  height: 100vh;
  width: 100vw;
}

.edit-profile-page .title {
  color: #eeeff3;
  font-weight: 600;
  position: absolute;
  top: 70px;
  left: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
  font-size: 20px !important;
}

.edit-profile-page .title a {
  color: #ff0000;
}

.edit-profile-page .title h3 {
  color: #eeeff3;
  font-size: 28px;
  margin-left: 10px;
}

.edit-profile-img {
  position: relative;
}

.edit-profile-img {
  cursor: pointer;
  position: relative;
  padding: 10px;
  border-radius: 50%;
  background-color: rgb(51, 50, 50);
  transition: 300ms;
  z-index: 1;
  margin: 10px;
  width: 200px !important;
  height: 200px !important;
  margin-left: auto;
  margin-top: 60px;
  margin-right: 50px;
}

.edit-profile-img .main-image {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
}

.profile-page .name-input {
  padding: 50px;
  width: 100%;
}

.profile-page .name-input input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  border-bottom: 2px solid #fff;
}

.profile-page .submit-btn {
  padding: 50px;
  width: 100%;
}

.profile-page .submit-btn button {
  color: #fff;
  background: #161616;
  outline: none;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 10px;
}

.profile-page .edit-btn button {
  color: #fff;
  background: #161616;
  outline: none;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 10px;
  border: 1px solid #fff;
  visibility: hidden;
}

.profile-page .profile-card:hover .edit-btn button {
  visibility: visible;
}

.profile-page .submit-btn button:hover {
  background-color: #f53538;
}

.movie-img img {
  max-height: 220px;
}

.page-content {
  margin-top: 120px !important;
}

.category-title {
  padding: 10px 20px;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  text-transform: uppercase;
  color: #f53538;
  font-weight: 900;
}

.channel-nav {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  border: 4px solid white;
  border-radius: 50%;
}

.channel-nav .icon-cont {
  padding: 5px;
  border: 2px solid white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channel-nav p {
  margin-left: 10px;
  color: white !important;
}

.channel-nav:hover,
.channel-nav.active {
  background: rgb(39, 38, 38);
}

.sidebar-nav {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;

}

.sidebar-nav .icon-cont {
  padding: 5px;
  border: 2px solid white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-nav p {
  margin-left: 10px;
  color: white !important;
}

.sidebar-nav:hover,
.sidebar-nav.active {
  background: rgb(39, 38, 38);
}

.left-side-menu.mobile .sidebar-nav.active p {
  color: #f53538 !important;
}

.left-side-menu {
  z-index: 1000 !important;
  top: 70px;
  position: fixed;
  height: 100vh;
}

.left-side-menu.mobile {
  top: 75px;
}

.left-side-menu ul {
  height: 90vh;
  padding-bottom: 500px !important;
}

.channel-video {
  width: 100% !important;
  height: 90vh !important;
  color: black !important;
  position: relative !important;
}

.channel-video .message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.channel-video .message .title {
  color: white;
  font-size: 34px;
  font-weight: bold;
}

.channel-video .message .text {
  color: white;
  font-size: 22px;
}

.channel-react-player {
  width: 95% !important;
  height: 95% !important;
}

#side-menu {
  overflow-y: scroll !important;
}

body {
  background-color: #000 !important;
  padding-bottom: 0 !important;
}

.admin-layout {
  background-color: #f3f5f7;
}

.navbar-custom {
  padding: 0 !important;
}

.show-details-episodes-section {
  padding-top: 70px;
}

.show-details-episodes-section ul {
  margin: 0;
  padding: 0;
}

.show-details-episodes-section .seasons-area li {
  color: white;
  padding: 15px 30px;
  border-radius: 10px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.show-details-episodes-section .seasons-area li>* {
  font-size: 16px;
  margin: 0;
}

.show-details-episodes-section .seasons-area li span {
  display: none;
}

.show-details-episodes-section .seasons-area li:hover {
  color: #ff0000;
}

.show-details-episodes-section .seasons-area li.active {
  background-color: #272727;
  color: #ff0000;
}

.show-details-episodes-section .seasons-area li.active span {
  display: inline-block;
}

.show-details-episodes-section .episodes-area {
  max-height: 85vh;
  overflow: scroll;
}

.show-details-episodes-section .episodes-area li {}

.show-details-episodes-section .episodes-area .episode-item {
  color: white;
  cursor: pointer;
  margin-bottom: 40px;
}

.show-details-episodes-section .episodes-area .episode-item * {
  margin: 0;
}

.show-details-episodes-section .episodes-area .episode-item img {
  width: 100%;
  border-radius: 10px;
}

.show-details-episodes-section .episodes-area .episode-item:hover img {
  transform: scale(1.05);
}

.show-details-episodes-section .episodes-area .episode-item .title-area {
  font-weight: 600;
  font-size: 15px;
}

.show-details-episodes-section .episodes-area .episode-item .title-area .title {
  font-family: bold;
  color: #ff0000;
  font-size: 20px;
  margin-bottom: 10px;
}


.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}


.form-control.react-select {
  padding: 0;

}

.react-select * {
  border: none !important;
  outline: none !important;
}

.not-allowed-message-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.not-allowed-message {
  font-size: 22px;
  color: white;
}

.not-allowed-footer-container {
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: 90vw;
  transform: translateX(-50%);
  font-size: 20px;
}

.video-loader.error-container {
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.share_plugin {
  display: none !important;
}

.share_plugin.share-hide {
  display: none !important;

}


.button-menu-mobile {
  height: fit-content !important;
  width: fit-content !important;
  line-height: 10px !important;
  margin-right: 10px;
}


.channel-list-section {}

.channel-list-section h4 {
  color: #f53538;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.channel-list-section .channel-item {

  height: 100%;
  width: 100%;
  border-radius: 100%;
}

.channel-list-section a {
  border-radius: 100%;
  margin: 10px;
  overflow: hidden;
  height: 80px;
  width: 80px;
}

.channel-list-section a.active {
  border-color: #f53538;
}

.channel-list-section .channel-item img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sidebar-overlay {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  backdrop-filter: blur(3px);
}

.sidebar-overlay.show {
  display: block;
}

.right-side {
  position: relative;
}

.header-search {
  width: fit-content;
  position: absolute;
  right: 45px;
}

.header-search .search-btn {
  border: 1px solid white;
  padding: 2px;
  border-radius: 50%;
  min-width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-search .search-toggle {
  display: flex;
}

.header-search .search-toggle i {
  font-size: 20px;
  color: white;
}

.header-search .search-wrap {
  position: relative;
  border: 1px solid white;
  border-radius: 20px;
  width: 200px;
  height: 35px;
  top: 50%;
  background-color: #222222;
}

.header-search .search-wrap input {
  position: absolute;
  background: transparent;
  border: none;
  outline: none;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
}

.header-search .search-wrap .search-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.search-toggle:hover i {
  color: #f53538 !important;
}
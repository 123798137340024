/* ==========================================================================
   #Custom HTML5 Video Player
   ========================================================================== */


.player-container {
    width: 100vw;
    height: 100vh;
    /* padding: 20px; */

    /* padding-bottom: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.video-container {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

video {
    width: 100%;
    height: auto;
    max-height: fit-content;
    border-radius: 4px;
    object-fit: contain;
}

.video-details {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));

}

.video-details.hide {
    opacity: 0;
    pointer-events: none;
}

.video-details .video-details-head {
    position: absolute;
    top: 40px;
    left: 0px;
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)); */
}

.video-details .video-details-head h4 {
    color: white;
    margin-left: 10px;
}

.video-details .video-details-body {
    position: absolute;
    top: 55%;
    left: 20px;
}

.video-details .video-details-body img {
    width: 400px;
    max-width: 45%;

}

.video-controls {
    right: 0;
    left: 0;
    padding: 10px;
    position: absolute;
    bottom: 40px;
    transition: all 0.2s ease;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)); */
}

.video-controls.hide {
    opacity: 0;
    pointer-events: none;
}

.video-progress {
    display: flex;
    height: 0.4rem;
    overflow: hidden;
    font-size: 0.65625rem;
    background-color: #eef0f2;
    border-radius: 0.25rem;
    margin-bottom: 10px;
    position: relative;
}

.video-progress input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.video-progress>.bg-danger {
    background-color: #f53538 !important;
}

progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2px;
    width: 100%;
    height: 8.4px;
    pointer-events: none;
    position: absolute;
    top: 0;
}

progress::-webkit-progress-bar {
    background-color: #474545;
    border-radius: 2px;
}

progress::-webkit-progress-value {
    background: var(--youtube-red);
    border-radius: 2px;
}

progress::-moz-progress-bar {
    border: 1px solid var(--youtube-red);
    background: var(--youtube-red);
}

.seek {
    position: absolute;
    top: 10px;
    width: 100%;
    cursor: pointer;
    margin: 0;
}

.seek:hover+.seek-tooltip {
    display: block;
}

.seek-tooltip {
    display: none;
    position: absolute;
    top: -50px;
    margin-left: -20px;
    font-size: 12px;
    padding: 3px;
    content: attr(data-title);
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
}

.bottom-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.left-controls {
    display: flex;
    align-items: center;
    color: #fff;
}

.left-controls {
    white-space: nowrap;
}

.right-controls {
    margin-left: auto;

}

.volume-controls {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.volume-controls input {
    width: 100px;
    opacity: 1;
    transition: all 0.4s ease;
}

.volume-controls:hover input,
.volume-controls input:focus {
    width: 100px;
    opacity: 1;
}

button {
    cursor: pointer;
    position: relative;
    margin-right: 7px;
    font-size: 12px;
    padding: 3px;
    border: none;
    outline: none;
    background-color: transparent;
}

button * {
    pointer-events: none;
}

button::before {
    content: attr(data-title);
    position: absolute;
    display: none;
    right: 0;
    top: -50px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-weight: bold;
    padding: 4px 6px;
    word-break: keep-all;
    white-space: pre;
}

/* button:hover::before {
    display: inline-block;
} */

.fullscreen-button {
    margin-right: 0;
}

.player-container .pip-button svg {
    width: 32px;
    height: 32px;
}

.playback-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px;
    background: #000000a3;
    border-radius: 50%;
}

input[type=range] {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 8.4px;
    background: transparent;
    cursor: pointer;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    border-radius: 1.3px;
    -webkit-appearance: none;
    transition: all 0.4s ease;
}

input[type=range]::-webkit-slider-thumb {
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background: var(--youtube-red);
    cursor: pointer;
    -webkit-appearance: none;
    margin-left: -1px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: transparent;
}

input[type=range].volume {
    height: 5px;
    background-color: #fff;
    border-radius: 10px;

}

input[type=range].volume::-webkit-slider-runnable-track {
    background-color: transparent;
}

input[type=range].volume::-webkit-slider-thumb {
    margin-left: 0;
    height: 14px;
    width: 14px;
    background: #fff;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    border: 1px solid transparent;
    background: transparent;
    border-radius: 1.3px;
}

input[type=range]::-moz-range-thumb {
    height: 14px;
    width: 14px;
    border-radius: 50px;
    border: 1px solid var(--youtube-red);
    background: var(--youtube-red);
    cursor: pointer;
    margin-top: 5px;
}

input[type=range]:focus::-moz-range-track {
    outline: none;
}

input[type=range].volume::-moz-range-thumb {
    border: 1px solid #fff;
    background: #fff;
}

.hidden {
    display: none;
}

.player-container svg {
    width: 28px;
    height: 28px;
    fill: #fff;
    stroke: #fff;
    cursor: pointer;
}






.video-loader {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10000;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));

}

.video-loader>div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.player-container .video-loader svg {
    width: 40px;
    height: 40px;
}

.video-container .next-video-card {
    position: absolute;
    right: 20px;
    bottom: 150px;
    max-width: 200px;
    /* padding: 10px; */
    border-radius: 20px;
    background-color: #474545;
    color: white;
    cursor: pointer;
}

.video-container .next-video-card img {
    width: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

}

.video-container .next-video-card .progress {
    height: 0.5rem;
}

.video-container .next-video-card .progress .progress-bar {
    background-color: #797979;
}

.video-container .next-video-card .body-area {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.video-container .next-video-card .next-title {
    margin: 0;

}

.video-container .next-video-card .video-card-title-area {
    margin: 10px 0;
}

.video-container .next-video-card .video-card-title-area h4 {
    color: white;
}

.video-container .next-video-card .video-card-title-area * {
    margin: 0;
}

.video-container .next-video-card button {
    padding: 5px 10px;
    background-color: white;
    color: black;
    font-weight: bold;
    border-radius: 10px;
    font-size: 16px;
}
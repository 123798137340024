/* Main Container */

.video-player-full {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: black;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  /* padding: 40px; */
}

/* Video */
/* .video-player-full .close-div, */
.video-player-full .react-player {
  padding-top: 0 !important;
  width: 90vw !important;
  height: 90vh !important;
  margin: auto;
  position: relative !important;
  cursor: pointer !important;
}

/* .video-player-full .close-div {
  width: fit-content !important;
  height: fit-content !important;
  color: white;
  position: absolute !important;
  top: 50px;
  right: 40px;
} */

.react-player video {
  width: 100% !important;
  height: 100% !important;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: auto !important;
  position: absolute;
}

.video-player-full .video-react-big-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.video-player-full .title-area {
  position: fixed;
  top: 0;

  z-index: 10000;
  background-color: rgba(17, 17, 17, 0.541);
  width: 100%;
  height: fit-content;
  transition: 0.5s;
  padding: 40px;



}

.video-player-full .title-area>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.video-player-full .title-area.hide {
  transform: translateY(-100%);
}

.video-player-full .title-area>div>p {
  /* padding: 0 40px; */
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.video-player-full .suggestions-area {
  position: fixed;
  bottom: 0;

  z-index: 10000;
  background-color: rgba(17, 17, 17, 0.541);
  width: 100%;
  height: fit-content;
  transition: 0.5s;
  padding-bottom: 20px;
}

.video-player-full .suggestions-area.hide {
  transform: translateY(100%);
}

/* Controls */

.controlsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background-color: transparent;
  margin-top: -50vw;
  padding: 0 40px;
  z-index: 20;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 18rem;
  margin: auto;
}

.controlsIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 10rem;
  margin-right: 10rem;
}

.controlsIcon--small {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 10rem;
  margin-right: 10rem;
}

/* The time controls section */

.timecontrols {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 4rem;
  margin-left: 10vw;
}

.time_progressbarContainer {
  background-color: gray;
  border-radius: 15px;
  width: 75vw;
  height: 5px;
  z-index: 30;
  position: relative;
  margin: 0 20px;
}

.time_progressBar {
  border-radius: 15px;
  background-color: indigo;
  height: 100%;
}

.controlsTime {
  color: white;
}
.mobile-layout {
    .content-page {
        padding: 0 10px !important;
    }

    .header-area-v2 {
        position: inherit;
        margin: 0 !important;
    }

    .hero-slider {
        height: 240px !important;
    }

    .hero-slider .single-hero-img-2 .content .button-div {
        margin-top: 0;
    }

    .hero-slider .single-hero-img-2 .content .text-img {
        width: 140px;
    }

    .video-slider {
        padding: 0 10px !important;
    }

    .video-slider-section>p {
        padding: 0 10px;
    }

    .movie-wrap-plr {
        height: 100%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .video-details-page .hero-slider .single-hero-img-2 .content {
        bottom: -15px !important;
    }


    .profile-page {
        position: fixed;

        .title {
            color: #eeeff3;
            font-weight: 600;
            position: absolute;
            top: 120px;
            left: 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            z-index: 2;
            font-size: 18px !important;
        }

        .edit-btn button {

            visibility: visible
        }

        .edit-profile-img {
            margin-top: 255px;
        }
    }

    .movie-img img {
        max-width: 150px;
    }

    .genre-wrap .title,
    .genre-wrap .title a {
        font-size: 18px;
    }

    .video-controls {
        top: 70%;
        bottom: auto;
    }

    .video-details .video-details-body {
        top: 62%;

    }

    .hero-slider .single-hero-img-2 .bg {
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: fit-content;
        object-fit: cover;
        transform: translateX(-50%);
    }

    .video-slider-section>div {
        padding: 0 10px;
    }

    .video-slider-section>div>p {
        font-size: 18px;
    }

    .video-slider-section>p {
        font-size: 18px;
    }

    .section-title-4 h2 {
        font-size: 18px;
    }

    .video-card-v2 .text-section .title {
        font-size: 14px;
    }

    .video-card-v2 .text-section p {
        font-size: 12px;
    }
}
body {
    background-color: #000000;
}

.bg-dark {
    background-color: #00000000 !important;
}

.navbar-toggler {
    color: #fff;
}

.bg-color {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 32px;
    padding: 5px 25px;
    margin-right: 10px;
}

div#navbarSupportedContent {
    justify-content: end;
}

#logsedf {
    width: 70%;
}

.navbar-nav .nav-link {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7em;
}

.active {
    color: #F53538 !important;
}

.pimg-header {
    margin: 10px;
}

#pimg {
    width: 37px;
    height: 37px;
}

.search-icon {
    padding: 4px 6px;
    border: 1px solid #fff;
    border-radius: 30px;
    color: #fff;
}

.search-icon:hover {
    color: #fff;
}

.iftiinhome-header-section {
    background-image: url(/public/assets/new-images/homeslider.png);
    height: 821px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.slider-section {
    width: 100%;
}

.header-text {
    position: absolute;
    width: 643px;
    height: 98px;
    left: 125px;
    top: 398px;
}

.header-text h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 42px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.header-text h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 29px;
    color: #F53538;
}

.btn-watch {
    background-color: #F53538;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 25px;
    border-radius: 6px;
    padding: 6px 40px;
    margin-top: 30px;
}

.btn-watch:hover {
    color: #fff !important;
    background-color: #000000;
    border: 1px solid #F53538;
}

.Continue-section {
    width: 100%;
}

.wcontiue h3 {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 30px;
}

.owl-theme .owl-dots .owl-dot span {
    display: none;
}

.card {
    border-radius: 16px;
}

.brands-section {
    width: 100%;
}

.brand-img {
    width: 200px;
    height: auto;
}

.treanding-section {
    width: 100%;
}

.sport-section {
    width: 100%;
}

.movies-header-section {
    background-image: url(/public/assets/new-images/movies-header.png);
    height: 821px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.horror-section {
    width: 100%;
}

.action-section {
    width: 100%;
}

.comicmov-section {
    width: 100%;
}

.thriller-section {
    width: 100%;
}

.family-section {
    width: 100%;
}

.shows-header-section {
    background-image: url(/public/assets/new-images/show-header.png);
    height: 821px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

#signin-button {
    color: #FFFFFF;
    background: #F53538;
    border-radius: 8px;
    padding: 8px 30px;
}

/* sign in css */
.sign-slider-section {
    background-image: url(/public/assets/new-images/1-1.jpg);
    min-height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.signin-header-text {
    position: absolute;
    width: 643px;
    /* height: 98px; */
    left: 125px;
    top: 50%;
    transform: translateY(-50%);
}

.signin-header-text h1 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 51px;
    line-height: 90px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.signin-header-text h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 54px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 5px 8px 11px rgba(0, 0, 0, 0.36);
}

.ifi-section {
    background-image: url(/public/assets/new-images/2.jpg);
    min-height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

#if-img {
    width: 176px;
    height: auto;
    padding: 8px 0px;
}

.ifi-text {
    position: absolute;
    height: 84px;
    left: 125px;
    top: 180px;
}

.ifi-text h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 84px;
    color: #FFFFFF;
}

.ifi-text p {
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    width: 400px;
}

.thi-section {
    background-image: url(/public/assets/new-images/3.jpg);
    min-height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

#thi-img {
    width: 175px;
    height: auto;
    padding: 8px 0px;
    float: right;
}

.thi-text {
    position: absolute;
    height: 84px;
    left: 125px;
    top: 230px;
}

.thi-text h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 84px;
    color: #FFFFFF;
}

.thi-text p {
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    width: 400px;
}

.comic-section {
    background-image: url(/public/assets/new-images/4.jpg);
    min-height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.spo-section {
    background-image: url(/public/assets/new-images/spo.png);
    height: 700px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.packplan-section {
    width: 100%;
}

.pack-text h1 {
    font-family: "Poppine", sans-serif;
    font-weight: 600;
    font-size: 40px;
    color: #FFFFFF;
}

.icon-box {
    background-color: #F53538;
    width: 140px;
    height: 95px;
    border-radius: 8px;
    padding: 15px;
}

.icon-box1 {
    background-color: #F53538;
    width: 140px;
    height: 95px;
    border-radius: 8px;
    padding: 15px;
    float: right;
}

.icon-box1 p {
    text-align: center;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
}

.icon-box1 span {
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    ;
    text-transform: uppercase;
    padding: 0px 15px;
}

.icon-box p {
    text-align: center;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
}

.icon-box span {
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    ;
    text-transform: uppercase;
    padding: 0px 15px;
}

/* sign css complete */
.plan {
    background-color: #F53538;
    padding: 10px 10px;
    margin: 12px 0px;
}

.plan li {
    display: flex;
    justify-content: space-between;
    font-family: "Poppine", sans-serif;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 0px 30px;
}

#icnb {
    color: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 30px;
    padding: 5px 5px;
    margin: 0px 40px;
}

.accordion-item {
    background-color: #000000;
    color: #fff;
    border: 1px solid #F53538;
}

.accordion-button:not(.collapsed) {
    background-color: #000000;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppine", sans-serif;
}

.accordion-button {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppine", sans-serif;
}




.footer-text-bottom p {
    text-align: center;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.footer-text p {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.footer-text h3 {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    text-align: left;
    font-weight: 500;
}

.footer-social ul li {
    display: inline-block;
    margin: 1px;
}

.footer-text ul {
    padding-left: 0px;
    white-space: nowrap;

}

.footer-text ul li {
    list-style: none;
    padding: 6px 10px;
}

.footer-text ul li a {
    font-family: "Poppins", sans-serif;
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    font-size: 15px;
}

.footer-social ul {
    padding: 15px 0px;
}

.footer-social ul li a {
    color: #fff;
    font-size: 16px;
    background-color: #F53538;
    border-radius: 50px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}

.footer-brand {
    width: 134px;
    height: auto;
}

@media screen and (min-width: 992px) and (max-width:1210px) {
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        justify-content: end;
    }

    a.nav-link {
        margin-left: 0px;
        font-size: 16px;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
        color: #000;
        height: 37px;
    }

    .iftiinhome-header-section {
        width: 100%;
    }

    .slider-section {
        width: 100%;
    }

    .header-text h1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 42px;
        text-transform: uppercase;
        color: #FFFFFF;
    }

    .header-text h3 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 40px;
        color: #F53538;
    }

    .Continue-section {
        width: 100%;
    }

    .wcontiue h3 {
        color: #fff;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        font-size: 30px;
    }

    .brands-section {
        width: 100%;
    }

    .treanding-section {
        width: 100%;
    }

    .sport-section {
        width: 100%;
    }

    .movies-header-section {
        width: 100%;
    }

    .horror-section {
        width: 100%;
    }

    .action-section {
        width: 100%;
    }

    .comicmov-section {
        width: 100%;
    }

    .thriller-section {
        width: 100%;
    }

    .family-section {
        width: 100%;
    }

    .shows-header-section {
        width: 100%;
    }
}

@media screen and (min-width:768px) and (max-width:991px) {
    a.nav-link {
        margin-left: 0px;
        font-size: 11px;
        font-family: "Poppins", sans-serif;
        color: #000;
        height: 37px;
    }

    .bg-color {
        background: #000000;
        border-radius: 0px;
    }

    .iftiinhome-header-section {
        width: 100%;
        height: 498px;
    }

    .slider-section {
        width: 100%;
    }

    .header-text {
        left: 40px;
        top: 220px;
    }

    .header-text h1 {
        font-size: 27px;
    }

    .header-text h3 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 24px;
        /* identical to box height */
        color: #F53538;
    }

    .btn-watch {
        font-size: 18px;
        padding: 3px 15px;
        margin-top: 8px;
    }

    .Continue-section {
        width: 100%;
    }

    .wcontiue h3 {
        font-size: 28px;
    }

    .search-icon {
        display: none;
    }

    #pimg {
        display: none;
    }

    .brands-section {
        width: 100%;
    }

    .treanding-section {
        width: 100%;
    }

    .sport-section {
        width: 100%;
    }

    .movies-header-section {
        width: 100%;
        height: 498px;
    }

    .horror-section {
        width: 100%;
    }

    .action-section {
        width: 100%;
    }

    .comicmov-section {
        width: 100%;
    }

    .thriller-section {
        width: 100%;
    }

    .family-section {
        width: 100%;
    }

    .shows-header-section {
        width: 100%;
        height: 498px;
    }

    .sign-slider-section {
        width: 100%;
        height: 617px;
    }

    .signin-header-text {
        left: 50px;
        top: 160px;
    }

    .signin-header-text h1 {
        font-weight: 600;
        font-size: 35px;
    }

    .signin-header-text h3 {
        font-size: 20px;
    }

    .ifi-section {
        height: 422px;
        width: 100%;
    }

    #if-img {
        width: 99px;
        height: auto;
    }

    .ifi-text {
        left: 172px;
        top: 100px;
    }

    .ifi-text h2 {
        font-weight: 600;
        font-size: 28px;
        line-height: 60px;
    }

    .ifi-text p {
        font-size: 15px;
        font-weight: 500;
        line-height: 30px;
    }

    .thi-section {
        height: 422px;
        width: 100%;
    }

    #thi-img {
        width: 99px;
        height: auto;
    }

    .thi-text {
        left: 280px;
        top: 100px;
    }

    .thi-text h2 {
        font-weight: 600;
        font-size: 28px;
        line-height: 60px;
    }

    .thi-text p {
        font-size: 15px;
        font-weight: 500;
        line-height: 30px;
    }

    .comic-section {
        height: 422px;
        width: 100%;
    }

    .spo-section {
        height: 422px;
        width: 100%;
    }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    a.nav-link {
        margin-left: 0;
        font-size: 12px;
        font-family: "Poppins", sans-serif;
        color: #000;
        height: 37px;
        border-bottom: 1px solid #8c8c8c;
        padding: 23px 0;
    }

    .bg-color {
        background: #000000;
        border-radius: 0px;
    }

    .iftiinhome-header-section {
        width: 100%;
        height: 237px;
    }

    .slider-section {
        width: 100%;
    }

    .header-text {
        position: absolute;
        height: 98px;
        left: 0px;
        top: 100px;
    }

    .header-text h1 {
        font-size: 24px;

    }

    .header-text h3 {
        font-weight: 500;
        font-size: 18px;
    }

    .btn-watch {
        font-weight: 500;
        font-size: 18px;
        border-radius: 6px;
        padding: 3px 15px;
        margin-top: 8px;
    }

    .Continue-section {
        width: 100%;
        padding: 10px;
    }

    .wcontiue h3 {
        font-size: 26px;
    }

    .search-icon {
        display: none;
    }

    #pimg {
        display: none;
    }

    .brands-section {
        width: 100%;
    }

    .brand-img {
        width: 170px;
        height: auto;
    }

    .treanding-section {
        width: 100%;
    }

    .sport-section {
        width: 100%;
    }

    .movies-header-section {
        width: 100%;
        height: 237px;
    }

    .horror-section {
        width: 100%;
    }

    .action-section {
        width: 100%;
    }

    .comicmov-section {
        width: 100%;
    }

    .thriller-section {
        width: 100%;
    }

    .family-section {
        width: 100%;
    }

    .shows-header-section {
        width: 100%;
        height: 237px;
    }

    .sign-slider-section {
        width: 100%;
        height: 293px;
    }

    .signin-header-text {
        left: 0px;
        top: 72px;
    }

    .signin-header-text h1 {
        font-weight: 600;
        font-size: 25px;
        line-height: 50px;
    }

    .signin-header-text h3 {
        font-size: 15px;
        line-height: 15px;
    }

    .ifi-section {
        height: 201px;
        width: 100%;
    }

    #if-img {
        width: 88px;
        height: auto;
        padding: 4px 12px
    }

    .ifi-text {
        left: 25px;
        top: 80px;
    }

    .ifi-text h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 5px;
    }

    .ifi-text p {
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        width: 100%;
    }

    .thi-section {
        height: 201px;
        width: 100%;
    }

    #thi-img {
        width: 88px;
        height: auto;
        padding: 4px 12px
    }

    .thi-text {
        left: 25px;
        top: 80px;
    }

    .thi-text h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 5px;
    }

    .thi-text p {
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        width: 100%;
    }

    .comic-section {
        height: 201px;
        width: 100%;
    }

    .spo-section {
        height: 201px;
        width: 100%;
    }
}